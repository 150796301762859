import React from "react"
import Top from "./Top"
import Bottom from "./Bottom"

const Footer = () => (
    <footer className="footer">
        <Top />
        <Bottom />
    </footer>
)
export default Footer
