import React, { useMemo } from "react"
import { Helmet } from "react-helmet"
import { Intent } from "../../intent"
// import logo from "../../../assets/img/header/menu/ic-logo.png";
import logo from "../../../assets/img/ic-logo.png"
import favicon from "../../../assets/img/favicon.ico"
import data from "./data"
import { capitalize } from "../../../utils/helpers"

const MenuItems = props => {
    const infoPage = props.infoPage
    const page = infoPage.page
    const subPage = infoPage.sub ? infoPage.sub : false

    const pageName = useMemo(() => {
        let fullName = capitalize(page)

        if (subPage) fullName += ` - ${capitalize(subPage)}`

        fullName += " | Sowx"

        return fullName
    }, [page, subPage])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageName}</title>
                <link rel="canonical" href="http://sowx.com.br" />
                <link
                    rel="shortcut icon"
                    type="image/png"
                    href={favicon}
                 />
                <meta
                    name="keywords"
                    content="gestão de Frota, gestão de frotas de veículos, sistema de gestão de frotas, monitoramento de frotas, rastreador, rastreador a venda, controle de frota, controle frota, de frota, empresas de gestão de frotas, gestão de frotas, gestão de frotas empresas, gestão de frotas software, gestão frota, mais frota, monitoramento frota, rastreador frota, rastreador para frota, rastreamento frota, Telemetria, gestão de frota de caminhões, +controle, +rfid, controle patrimonial rfid, +rfid, +industria, +estoque, +rfid, +controle, +expedição, +rfid, +controle, +movimentação, +rfid, gestão estoque rfid, +gestão, +rfid, controle patrimônio rfid, +controle, +itens, +retornáveis, +rfid, +sistema, +rfid, +antifurto, +rfid, +rfid, +varejo, +Inventário, +rfid, gestão patrimonial rfid, gestão patrimônio rfid, +RFID, +Patrimonial, +rfid, +controle, +acesso, +rfid, +rastreabilidade, +rfid, +solução, +rfid, +RFID, +Patrimônio, controle enxoval hospital, gestão patrimonial rfid, gestão patrimônio rfid, gestão vida útil têxtil hospital, gestão de pneus, rfid pneus, gestão enxoval hospital, RFID Pneus, RFID pneu, controle enxoval hotel, gestão vida util textil hospital, gestão estoque rfid, gestão enxoval hotel, controle vida util textil hospital, controle vida útil têxtil hospital"
                />
                <meta
                    name="facebook-domain-verification"
                    content="rpejz7k0v8y0ltw9n6aj7pikiqkpbq"
                />
            </Helmet>
            <header className={!props.isMobile ? "menu" : "menu mobile"}>
                <div className={props.isMobile ? "display-none" : null}>
                    <Intent to="/">
                        <img src={logo} alt="sowx" className="logo" />
                    </Intent>
                </div>
                <div className="d-f-a-c">
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={
                                page.toLowerCase() === item.Name.toLowerCase()
                                    ? "menu__box menu--active"
                                    : "menu__box"
                            }
                        >
                            <Intent to={item.URL}>
                                <span>{item.Name.toUpperCase()}</span>
                            </Intent>
                        </div>
                    ))}
                </div>
            </header>
        </>
    )
}

export default MenuItems
