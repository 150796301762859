import React, { useState, useEffect, useMemo } from "react"
import { Helmet } from "react-helmet"
import CircularProgress from "@material-ui/core/CircularProgress"
// import WhatsAppWidget from "react-whatsapp-widget"
import Header from "../header/header"
import Footer from "../footer/footer"
import { FloatingButton } from "../floating-button"
import { ContactButton } from "../contact-button"
import "../../assets/scss/_main.scss"
import icoLogo from "../../assets/img/ic-logo-ico.png"
import "react-whatsapp-widget/dist/index.css"

const windowGlobal = typeof window !== "undefined" && window
const Layout = ({ children, infoPage }) => {
    const [size, setSize] = useState(1920)

    useEffect(() => {
        setSize(windowGlobal.innerWidth)
        const handleResize = () => {
            setSize(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
    }, [])

    const isMobile = useMemo(() => size <= 840, [size])

    return (
        <div style={{ marginTop: isMobile ? "10vh" : "72px" }}>
            <div className="loader js-loader display-none">
                <CircularProgress size={80} thickness={4} color="inherit" />
                <img src={icoLogo} alt="logo" />
            </div>
            <Helmet>
                <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-NRJDVVH')`}
                </script>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=AW-630763596"
                />
                <noscript>
                    {`
                        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NRJDVVH" height="0" width="0" style="display:none;visibility:hidden"></iframe>
                    `}
                </noscript>
            </Helmet>
            <div className="main">
                {/* <WhatsAppWidget
                    phoneNumber="555198800846"
                    textReplyTime="Geralmente responde dentro de alguns minutos"
                    message={"Olá! 👋🏼 \n\nO que podemos fazer por você?"}
                    companyName="Sowx"
                    sendButton="Enviar"
                    placeholder="Digite uma mensagem"
                /> */}
                <Header infoPage={infoPage} smallScreen={isMobile} />
                <div>{children}</div>
                <Footer />
            </div>
            <FloatingButton />
            <ContactButton />
        </div>
    )
}

export default Layout
