import React, { useState } from "react"
import MenuItems from "./MenuItems"

import logoIMG from "../../../assets/img/ic-logo.png"
import hamburgerIcon from "../../../assets/img/menu.svg"
import closeIcon from "../../../assets/img/close.svg"

const Menu = ({ infoPage, smallScreen }) => {
    const [open, setOpen] = useState(false)

    const openSidebar = () => {
        document.getElementById("sidebar").style.display = "flex"
        setOpen(true)
    }

    const closeSidebar = () => {
        document.getElementById("sidebar").style.display = "none"
        setOpen(false)
    }

    return smallScreen ? (
        <div className="sidebar">
            <div className="menu-header-row">
                {open ? (
                    <button
                        type="button"
                        className="sidebar-button-on"
                        onClick={closeSidebar}
                    >
                        <img src={closeIcon} alt="Menu" className="menu-icon" />
                    </button>
                ) : (
                    <button
                        type="button"
                        className="sidebar-button-off"
                        onClick={openSidebar}
                    >
                        <img
                            src={hamburgerIcon}
                            alt="Menu"
                            className="menu-icon"
                        />
                    </button>
                )}
                <div className="logo-box">
                    <img src={logoIMG} alt="Menu" className="menu-logo" />
                </div>
            </div>
            <div
                className="sidebar-inner"
                style={{ display: "none" }}
                id="sidebar"
                onClick={closeSidebar}
                onKeyDown={closeSidebar}
                role="button"
                tabIndex={0}
            >
                <MenuItems infoPage={infoPage} isMobile={smallScreen} />
            </div>
        </div>
    ) : (
        <>
            <MenuItems infoPage={infoPage} isMobile={smallScreen} />
        </>
    )
}

export default Menu
