import React, { useEffect, useState } from "react"

const FloatingButton = () => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const hasScrolled =
                document.documentElement.scrollTop > 560 ||
                document.body.scrollTop > 560

            if (hasScrolled) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        })
    }, [])

    const handleButtonClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    return (
        <button
            type="button"
            className={`floating-button ${visible && "visible"}`}
            onClick={handleButtonClick}
            onKeyDown={handleButtonClick}
        >
            <span />
        </button>
    )
}

export default FloatingButton
