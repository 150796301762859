import React from "react"

const Bottom = () => (
    <section className="bottom">
        <span className="medium">
            COPYRIGHT @{new Date().getFullYear()} SOWX. TODOS OS DIREITOS
            RESERVADOS. BY:{" "}
            <a href="https://adac.dev/" target="_blank" rel="noreferrer">
                ADAC
            </a>
        </span>
    </section>
)

export default Bottom
